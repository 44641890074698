



































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import BEditableTable from 'bootstrap-vue-editable-table'
import {
    BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BButton, BFormCheckbox, BSpinner, BTable, BFormSelect, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BForm
} from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { EvolutionDomaineIntervention, FilterModel } from "@/api/models/rapportActivites/rapportActivite";
import { successAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ApexOptions } from 'apexcharts';
import { EnumNumberStat } from "@/api/models/enums/enumNumberStat";

@Component({
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BFormSelect,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton,
    BFormCheckbox,
    BSpinner,
    BTable,
    BEditableTable,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    ValidationProvider,
    ValidationObserver
  },
  name: "template-nombre",
})
export default class NombreTemplateRapport extends Vue {
  @Prop({ required: true }) filters?: FilterModel;
  @Prop({ required: true }) title?: string;
  @Prop({ required: true }) statType?: EnumNumberStat;
  @Prop({ required: false, default: undefined }) specificId?: EnumNumberStat;

  numberToShow: number = 0;

  locked = true;
  disabled = false;

  loading: boolean = false;

  async getTitle() {
    return this.title;
  }
  
  async loadTrueData() {
    this.disabled = true;
    this.loading = true;
    this.locked = false;
    this.loadStatistiques();
  }

    async copyToClipBoard() {
        await navigator.clipboard.writeText(this.numberToShow.toString()).then(() => {
            successAlert.fire({
                title: "Copie des données",
                text: "Les données ont bien été copié dans le presse papier",
            });
        });
    }

    async loadStatistiques() {
        try {
            if (this.filters) {
                this.filters.statType = this.statType;
            }
            // Récupérer les données depuis l'API
            await this.$http.ressifnet.rapportActivites.number(this.filters, this.specificId)
                .then((x: any) => {
                  this.locked = false;
                  this.loading = false;
                  this.disabled = false;
                  this.numberToShow = x.hasNumberDouble ? x.numberDouble : x.number;
            }).catch((e: any) => {
                this.loading = false;
                this.locked = true;

                let message = e.response?.data?.errors ? 
                `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
                `Un problème est survenu lors du chargement du graphique ${this.constructor.name}`;

                console.error(e)
                this.$swal({
                title: 'Erreur',
                text: message,
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
                })
            });
        }
        catch (error: any) {
            console.error(error);
        }
    }

}
